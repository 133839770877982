<template>
  <div>
    <div v-for="(i, index) in itemCount" :key="index" class="d-flex">
      <v-autocomplete
        style="z-index: 200"
        :disabled="disabled"
        v-model="parameter_id[i - 1]"
        :items="parameters"
        item-text="title[en]"
        :search-input.sync="parameterSearch[i - 1]"
        item-value="index"
        hide-details
        class="m-1"
        label="Search for a Parameters..."
        @change="changeParameter(i - 1)"
        outlined
        dense
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              To Create new Parameter :
              <span class="ml-3"
                ><v-btn
                  color="primary"
                  @click="CreateParameter(i - 1)"
                  :loading="isLoadingParameter"
                >
                  Create
                </v-btn>
              </span>
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.title.en"></v-list-item-title>
            <v-list-item-subtitle v-text="item.type"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-if="parameterSearch[i - 1] != 'Pattern'"
        :disabled="disabled || !parameter_id[i - 1]"
        v-model="value_id[i - 1]"
        :items="values[i - 1]"
        :search-input.sync="valueSearch[i - 1]"
        item-text="title[en]"
        item-value="index"
        class="m-1"
        hide-details
        label="Search for a parameters..."
        @keyup.enter="getSkuItems"
        @change="changeValue"
        outlined
        dense
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              To Create new Value :
              <span class="ml-3"
                ><v-btn
                  color="primary"
                  @click="AddValue(i - 1)"
                  :loading="isLoadingValue"
                >
                  Create
                </v-btn>
              </span>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>

      <v-file-input
        v-if="parameterSearch[i - 1] == 'Pattern'"
        :disabled="disabled || !parameter_id[i - 1]"
        v-model="image_pattern"
        label="Upload a pattern"
        class="m-1"
        @change="UploadPattern(i - 1)"
        accept="image/png, image/jpeg, image/bmp, video/mp4"
        required
        outlined
        dense
        clearable
      >
      </v-file-input>
      <span class="mt-2"
        ><v-icon left @click="removeImageItem(i - 1)">
          mdi-delete
        </v-icon></span
      >
    </div>
    <v-btn
      class="mr-4"
      color="primary"
      @click="addNewItem"
      :disabled="disabled"
    >
      add
    </v-btn>
  </div>
</template>

<script>
// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { UPDATE_CREATE_DATA } from "@/core/services/store/products.module";

export default {
  name: "AddParameter",
  props: [
    "disabled",
    "parameters",
    "updateParameter",
    "refresher",
    "paramValues",
  ],
  data: () => ({
    parameter_id: [],
    value_id: [],
    value: null,
    isLoadingParameter: false,
    isLoadingValue: false,
    parameterSearch: [],
    valueSearch: [],
    values: [],
    itemCount: 0,
    image_pattern: null,
  }),
  mounted() {
    this.resetData();
  },
  methods: {
    resetData() {
      this.parameter_id = [];
      this.value_id = [];
      this.parameterSearch = [];
      this.valueSearch = [];
      this.values = [];
      this.value = null;
      this.isLoadingParameter = false;
      this.isLoadingValue = false;
      this.itemCount = 0;
      this.image_pattern = null;
      if (this.paramValues) {
        this.itemCount = this.paramValues.length;

        this.paramValues.map((item, key) => {
          this.value_id.push(item.index);
          this.parameter_id.push(item.parent_id);
          this.changeParameter(key);
        });
      }
    },
    changeParameter(i) {
      var selectedItem = this.parameters.filter(
        (item) => item.index == this.parameter_id[i]
      );
      if (selectedItem[0].values && selectedItem[0].values.length > 0)
        this.values[i] = selectedItem[0].values;
      else this.values[i] = [];
    },
    changeValue() {
      this.$emit("update", this.value_id);
    },
    addNewItem() {
      this.itemCount++;
    },
    message(title, message, icon) {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        showConfirmButton: false,
        timer: 2500,
      });
    },
    CreateParameter(index) {
      const data = {
        title: this.parameterSearch[index],
      };
      ApiService.post("/stores/portal/parameters/store", data)
        .then(() => {
          this.$store.dispatch(UPDATE_CREATE_DATA, {}).then(() => {
            this.isLoadingParameter = false;
          });
        })
        .catch(() => {
          this.loader(false);
        });
    },
    AddValue(index) {
      const data = {
        parameter_id: this.parameter_id[index],
        values: [this.valueSearch[index]],
      };
      ApiService.post("/stores/portal/parameters/values/store", data)
        .then(() => {
          this.$store.dispatch(UPDATE_CREATE_DATA, {}).then(() => {
            this.isLoadingValue = false;
            this.changeParameter(index);
          });
        })
        .catch(() => {
          this.loader(false);
        });
    },
    UploadPattern(index) {
      let formData = new FormData();
      formData.append("parameter_id", this.parameter_id[index]);
      formData.append("values[]", this.image_pattern);
      ApiService.post("/stores/portal/parameters/values/store", formData)
        .then((result) => {
          let new_pattern_value = result.data.value.values.pop();
          this.value_id[index] = new_pattern_value.index;
          this.changeValue();
        })
        .catch(() => {
          this.loader(false);
          this.isLoadingValue = false;
        });
    },
    removeImageItem(index) {
      this.value_id = this.value_id.filter((item, key) => key != index);
      this.values = this.values.filter((item, key) => key != index);
      this.parameter_id = this.parameter_id.filter((item, key) => key != index);
      this.itemCount--;
      this.changeValue();
    },
  },
};
</script>
