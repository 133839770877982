<template>
  <div>
    <div>
      <v-btn color="primary" @click="$refs.FileInput.click()"
        ><v-icon>mdi-upload</v-icon>Upload Image</v-btn
      >
      <input
        ref="FileInput"
        type="file"
        style="display: none"
        @change="onFileSelect"
      />
    </div>
    <v-dialog v-model="dialog" width="800">
      <div class="bg-white poppins">
        <div>
          <VueCropper
            v-show="selectedFile"
            ref="cropper"
            :src="selectedFile"
            alt="Source Image"
            class="w-100"
          ></VueCropper>
        </div>
        <v-card-actions>
          <v-btn class="primary" @click="saveImage(), (dialog = false)"
            >Crop</v-btn
          >
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  name: "UploadCropper",
  components: { VueCropper },
  props: ["insertImage"],
  data() {
    return {
      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      dialog: false,
      files: "",
      fileName: "",
    };
  },
  methods: {
    saveImage() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      const fileName = this.fileName;
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const item = {
          image: blob,
          name: fileName,
        };
        this.insertImage(item);
      }, this.mime_type);
      this.resetData();
    },
    resetData() {
      this.fileName = "";
      this.files = "";
      this.image = "";
      this.selectedFile = "";
      this.cropedImage = "";
      this.mime_type = "";
      this.dialog = false;
      this.autoCrop = false;
    },
    onFileSelect(e) {
      const file = e.target.files[0];
      this.fileName = file.name;
      this.mime_type = file.type;
      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
};
</script>
