<template>
  <v-tooltip right class="pointer">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" :class="itemClass" class="pointer">
        Show
      </span>
    </template>
    <div>
      <div v-for="(item, index) in items" :key="item.index + index">
        <span
          >{{ getParameterName(item.parent_id) }} :{{ item.title.en }}
        </span>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: "ShowParameters",
  props: ["parameters", "items", "itemClass"],
  methods: {
    getParameterName(parent_id) {
      if (parent_id) {
        const element = this.parameters.filter(
          (item) => item.index == parent_id
        );
        return element[0].title.en;
      } else return "";
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
