<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Show Sku</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <v-container v-if="items">
            <v-data-table
              v-model="selected"
              fixed-header
              light
              dense
              show-select
              :headers="headers"
              :items="items"
              :hide-default-footer="true"
              class="elevation-1"
            >
              <template #item.images="{ item, header, value }">
                <show-image-carousel :items="value"></show-image-carousel>
              </template>
              <template #item.is_default="{ item, header, value }">
                <user-status :value="value"></user-status>
              </template>
              <template #item.parameters="{ item, header, value }">
                <show-parameters
                  :items="value"
                  :parameters="parameters"
                ></show-parameters>
              </template>
              <template #item.action="{ item, header, value }">
                <edit-sku
                  :item="item"
                  :refresh="getSkuItems"
                  :ref="`editSkuModal_${item.id}`"
                  :product_id="product_id"
                ></edit-sku>
                <span
                  ><v-icon left @click="editItem(item)">
                    mdi-square-edit-outline
                  </v-icon></span
                >
                <span
                  ><v-icon left @click="removeItem(item)">
                    mdi-delete
                  </v-icon></span
                >
              </template>
            </v-data-table>
          </v-container>
          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";
import axios from "axios";
import Swal from "sweetalert2";
import UserStatus from "@/own/components/datatable/UserStatus.vue";
import ShowParameters from "@/own/components/stores/products/skus/ShowParameters.vue";
import ShowImageCarousel from "@/own/components/datatable/ShowImageCarousel.vue";
import EditSku from "@/own/components/stores/products/skus/EditSku.vue";

export default {
  name: "ShowSku",
  components: {
    UserStatus,
    ShowParameters,
    ShowImageCarousel,
    EditSku,
  },
  props: ["loader", "product_id"],
  data: () => ({
    dialog: false,
    selected: [],
    item: null,
    parameters: null,
    headers: [
      {
        value: "id",
        text: "ID",
        type: "text",
        sortable: true,
        exportable: true,
        visible: true,
        align: "left",
        itemClass: "second-text",
        width: "100px",
        class: "text-muted fw-bolder font-size-sm text-uppercase poppins-ls",
      },
      {
        value: "images",
        text: "Image",
        type: "text",
        sortable: true,
        exportable: true,
        visible: true,
        align: "left",
        itemClass: "second-text",
        width: "100px",
        class: "text-muted fw-bolder font-size-sm text-uppercase poppins-ls",
      },
      {
        value: "price",
        text: "Price",
        type: "text",
        sortable: true,
        exportable: true,
        visible: true,
        align: "left",
        itemClass: "second-text",
        width: "100px",
        class: "text-muted fw-bolder font-size-sm text-uppercase poppins-ls",
      },
      {
        value: "discounted_price",
        text: "Discounted Price",
        type: "text",
        sortable: true,
        exportable: true,
        visible: true,
        align: "left",
        itemClass: "second-text",
        width: "100px",
        class: "text-muted fw-bolder font-size-sm text-uppercase poppins-ls",
      },
      {
        value: "parameters",
        text: "Parameters",
        type: "text",
        sortable: true,
        exportable: true,
        visible: true,
        align: "left",
        itemClass: "second-text",
        width: "100px",
        class: "text-muted fw-bolder font-size-sm text-uppercase poppins-ls",
      },
      {
        value: "is_default",
        text: "Home Page",
        type: "text",
        sortable: true,
        exportable: true,
        visible: true,
        align: "left",
        itemClass: "second-text",
        width: "100px",
        class: "text-muted fw-bolder font-size-sm text-uppercase poppins-ls",
      },
      {
        value: "action",
        text: "Actions",
        type: "text",
        sortable: true,
        exportable: true,
        align: "center",
        itemClass: "second-text",
        width: "100px",
        class: "text-muted fw-bolder font-size-sm text-uppercase poppins-ls",
      },
    ],
    items: null,
  }),
  methods: {
    toggleModal() {
      this.getSkuItems();
    },
    closeModal() {
      this.dialog = !this.dialog;
    },
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loader(true);
          let config = {
            method: "post",
            url: `${process.env.VUE_APP_BASE_URL}/stores/portal/skus/destroy`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            data: {
              sku_id: item.id,
            },
          };
          axios(config)
            .then(() => {
              Swal.fire("Deleted!", "The product has been deleted.", "success");
              this.getSkuItems();
            })
            .catch(() => {
              Swal.fire("Error!", "Something went wrong.", "error");
              this.loader(false);
            });
        }
      });
    },
    editItem(item) {
      this.$refs[`editSkuModal_${item.id}`].toggleModal();
    },
    getSkuItems() {
      this.loader(true);
      const editUrl = `${process.env.VUE_APP_BASE_URL}/stores/portal/products/edit`;
      let config = {
        method: "post",
        url: `${editUrl}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        data: {
          product_id: this.product_id,
        },
      };
      axios(config)
        .then((data) => {
          this.loader(false);
          this.items = data.data.product.skus;
          this.parameters = data.data.parameters;
          this.dialog = true;
        })
        .catch(() => {
          this.errorMessage("Something went wrong!");
          this.loader(false);
        });
    },
  },
};
</script>
