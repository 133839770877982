<template>
  <div class="p-4 bg-white rounded-lg">
    <div class="mb-7 mx-4 mt-6 d-flex align-center justify-space-between">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="tableProperties"
      ></datatable-detail>
      <div class="d-flex align-center">
        <!--        <button-->
        <!--          @click="() => $refs.addModal.toggleModal()"-->
        <!--          class="btn btn&#45;&#45;export-filter mr-3"-->
        <!--        >-->
        <!--          <span class="svg-icon">-->
        <!--            <v-icon size="18">mdi-plus-box-outline</v-icon>-->
        <!--          </span>-->
        <!--          Add-->
        <!--        </button>-->
        <button
          @click="() => $refs.builderModal.toggleModal()"
          class="btn btn--export-filter"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-export-variant</v-icon>
          </span>
          Builder
        </button>
      </div>
    </div>

    <BuilderDialog ref="builderModal" />
  </div>
</template>

<script>
// import DataTable from "@/own/components/storeNew/products/DataTable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import BuilderDialog from "@/own/components/orderManagement/rules/ruleBuilder/BuilderDialog";
export default {
  name: "Products",
  components: { BuilderDialog, DatatableDetail },
  // components: { DataTable },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, false);
  },
  data: function () {
    return {
      barcode: "",
      result: true,
    };
  },
  computed: {
    tableProperties: function () {
      return {
        title: "QC",
        description: "SKU Unit Management",
      };
    },
  },
};
</script>

<style scoped>
.custom-bg {
  background-color: #eef0f8;
}
.custom-row-height {
  height: calc(100vh - 171px) !important;
}

.custom-height {
  height: 25vh;
}

.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}
</style>
