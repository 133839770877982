<template>
  <span :class="btnColorSetter(value)" class="my-1">
    {{ value }}
  </span>
</template>

<script>
/**
 * component for displaying styled user status
 * api name =
 */
export default {
  name: "UserStatus",
  props: ["value"],
  methods: {
    btnColorSetter(tp) {
      var res = " px-3 py-1 uppercase status-text-color rounded text-center";
      if (tp === "Active") {
        return res + " status-active ";
      } else if (tp === "Unverified") {
        return res + " status-unverified ";
      } else if (tp === "Suspended") {
        return res + " status-suspended ";
      } else if (tp === true) {
        return res + " primary text-white";
      } else if (tp === false) {
        return res + " bg-danger text-white";
      } else if (tp === "Yes") {
        return res + " primary text-white";
      } else if (tp === "No") {
        return res + " bg-danger text-white";
      } else if (tp === "Api") {
        return res + " bg-info text-white";
      } else if (tp === "Manual") {
        return res + " bg-warning text-white";
      } else if (tp === "Verified") {
        return res + " status-verified ";
      }
    },
  },
};
</script>

<style scoped>
.status-text-color {
  border-radius: 8px !important;
  font-size: 10px;

  text-transform: uppercase;
  font-weight: 800 !important;
}
.status-active {
  color: #22912c;
  background-color: #a6d48f !important;
}
.status-suspended {
  color: #6a6a6a;
  background-color: #c4c4c4 !important;
}
.status-unverified {
  color: #a29f05;
  background-color: #ffef95 !important;
}
.status-verified {
  color: #22912c;
  background-color: #a6d48f !important;
}
</style>
