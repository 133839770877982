var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"pr-3",attrs:{"cols":"3"}},[_vm._t("ruleID",function(){return [_c('v-select',{attrs:{"items":_vm.ruleIDOptions,"item-text":"title","item-value":"name","dense":"","outlined":""},model:{value:(_vm.rule.id),callback:function ($$v) {_vm.$set(_vm.rule, "id", $$v)},expression:"rule.id"}})]},{"rule":_vm.rule,"options":_vm.ruleIDOptions})],2),_c('b-col',{staticClass:"pr-3",attrs:{"cols":"4"}},[_vm._t("ruleOperator",function(){return [_c('v-select',{attrs:{"label":"Condition","items":_vm.ruleOperatorOptions,"item-text":"title","item-value":"name","dense":"","outlined":""},model:{value:(_vm.rule.operator),callback:function ($$v) {_vm.$set(_vm.rule, "operator", $$v)},expression:"rule.operator"}})]},{"rule":_vm.rule,"options":_vm.ruleOperatorOptions})],2),(_vm.ruleParams)?_c('b-col',{staticClass:"pr-3",attrs:{"cols":"5"}},[(
            _vm.ruleParams.type === 'dropdown_multi_search' ||
            (_vm.ruleParams.type === 'dropdown_multi_search_area' &&
              _vm.rule.operator !== 'is_empty')
          )?_vm._t("dropdown_multiple",function(){return [_c('v-combobox',{attrs:{"label":_vm.ruleParams.title,"items":_vm.ruleParams.options,"item-text":"text","return-object":"","cache-items":"","dense":"","outlined":"","clearable":"","multiple":_vm.multiple,"small-chips":"","deletable-chips":"","hide-no-data":"","hide-selected":"","placeholder":"Enter at least 2 characters.","loading":_vm.isLoading,"search-input":_vm.search,"error-messages":_vm.ruleValueErrors},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"blur":function($event){return _vm.$v.rule.value.$touch()}},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", $$v)},expression:"rule.value"}})]},{"rule":_vm.rule,"multiple":_vm.multiple,"options":_vm.ruleParams.options}):(_vm.ruleParams.type === 'number')?_vm._t("number",function(){return [_c('v-text-field',{attrs:{"label":_vm.ruleParams.title,"dense":"","outlined":"","clearable":"","type":"number","hide-details":"","error-messages":_vm.ruleValueErrors},on:{"blur":function($event){return _vm.$v.rule.value.$touch()}},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", $$v)},expression:"rule.value"}})]},{"rule":_vm.rule}):(
            _vm.ruleParams.type === 'text' && _vm.rule.operator !== 'is_empty'
          )?_vm._t("text",function(){return [_c('v-text-field',{attrs:{"label":_vm.ruleParams.title,"dense":"","outlined":"","clearable":"","hide-details":"","error-messages":_vm.ruleValueErrors},on:{"blur":function($event){return _vm.$v.rule.value.$touch()}},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", $$v)},expression:"rule.value"}})]},{"rule":_vm.rule}):(
            _vm.ruleParams.type === 'dropdown' || _vm.ruleParams.type === 'dropdown2'
          )?_vm._t("dropdown",function(){return [_c('v-autocomplete',{attrs:{"label":_vm.ruleParams.title,"items":_vm.ruleParams.values,"item-text":"text","item-value":"index","return-object":"","dense":"","outlined":"","clearable":"","hide-details":"","error-messages":_vm.ruleValueErrors},on:{"blur":function($event){return _vm.$v.rule.value.$touch()}},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", $$v)},expression:"rule.value"}})]},{"rule":_vm.rule}):(
            _vm.ruleParams.type === 'dropdown_multi_select' ||
            _vm.ruleParams.type === 'dropdown_multi_select_area'
          )?_vm._t("dropdown",function(){return [_c('v-autocomplete',{attrs:{"label":_vm.ruleParams.title,"items":_vm.ruleParams.values,"item-text":"text","item-value":"index","small-chips":"","deletable-chips":"","return-object":"","multiple":"","dense":"","outlined":"","clearable":"","hide-details":"","error-messages":_vm.ruleValueErrors},on:{"blur":function($event){return _vm.$v.rule.value.$touch()}},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", $$v)},expression:"rule.value"}})]},{"rule":_vm.rule}):(_vm.ruleParams.type === 'time_range')?_vm._t("dropdown",function(){return [_c('div',{staticClass:"row ma-0 pa-0"},[_c('div',{staticClass:"col-6 ma-0 pa-0 pr-3"},[_c('v-select',{attrs:{"item-text":"text","item-value":"index","items":[
                  // { index: 0, text: ' 0 am' },
                  ...Array.from({ length: 24 }, (_, i) => ({
                    index: i,
                    text: `${i % 12 != 0 ? i % 12 : i != 0 ? 12 : 0} ${
                      i < 12 ? 'am' : 'pm'
                    }`,
                  })),
                ],"placeholder":"Start time","dense":"","outlined":"","clearable":"","error-messages":_vm.ruleValueErrors},on:{"blur":function($event){return _vm.$v.rule.value.$touch()}},model:{value:(_vm.rangeValue[0]),callback:function ($$v) {_vm.$set(_vm.rangeValue, 0, $$v)},expression:"rangeValue[0]"}})],1),_c('div',{staticClass:"col-6 ma-0 pa-0 pr-3"},[_c('v-select',{attrs:{"items":_vm.timeRangeEndValue,"placeholder":"End time","item-text":"text","item-value":"index","dense":"","outlined":"","clearable":"","error-messages":_vm.ruleValueErrors},on:{"blur":function($event){return _vm.$v.rule.value.$touch()}},model:{value:(_vm.rangeValue[1]),callback:function ($$v) {_vm.$set(_vm.rangeValue, 1, $$v)},expression:"rangeValue[1]"}})],1)])]},{"rule":_vm.rule}):_vm._e()],2):_vm._e()],1)],1),_c('div',{staticClass:"max-h-65"},[_c('button',{staticClass:"h-40px w-40px btn btn-sm btn-icon btn-light-info btn-hover-light",on:{"click":_vm.deleteRule}},[_c('span',{staticClass:"svg-icon"},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-delete")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }